import React, { useEffect } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function InterestProfile({
  title,
  subtitle,
  array,
  openWhenAdded = false,
  removeItem,
  addItem,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleActionIcon(id) {
    if (addItem) {
      return (
        <IconButton
          onClick={() => addItem(id)}
          edge="end"
          aria-label="add interest"
        >
          <PlaylistAddIcon />
        </IconButton>
      );
    } else if (removeItem) {
      return (
        <IconButton
          onClick={() => removeItem(id)}
          edge="end"
          aria-label="remove interest"
        >
          <PlaylistRemoveIcon />
        </IconButton>
      );
    } else {
      return null;
    }
  }

  React.useEffect(() => {
    if (openWhenAdded) {
      setIsOpen(true);
    }
  }, [openWhenAdded]);

  return (
    <>
      <Stack
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          cursor: 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Stack direction="column">
          <Typography color="text.secondary" variant="h6">
            {title}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {subtitle}
          </Typography>
        </Stack>
        <ArrowDropDownIcon
          sx={{
            fontSize: '3rem',
            fill: '#000000',
            rotate: isOpen ? '180deg' : 0,
            transition: 'all 0.3s ease',
          }}
        />
      </Stack>
      <List
        sx={{
          display: isOpen ? 'block' : 'none',
          marginBottom: '1rem',
        }}
      >
        {array.length > 0 ? (
          array.map(({ id, title }, index) => (
            <ListItem
              key={`${id}-${index}`}
              secondaryAction={handleActionIcon(id)}
            >
              <ListItemText primary={title} secondary={null} />
            </ListItem>
          ))
        ) : (
          <Typography color="text.secondary" variant="body2" textAlign="center">
            You have not declared any interests yet.
          </Typography>
        )}
      </List>
    </>
  );
}
