import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Dialog } from '@capacitor/dialog';
import { Buffer } from 'buffer';
import { Link, navigate } from 'gatsby';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { App } from '@layouts';
import { DataUsedModal, DataNotUsedModal, SplashScreen } from '@components';
import useUser from '@hooks/useUser';
import useNotifications from '@hooks/useNotifications';
import useTopics from '@hooks/useTopics';
import {
  dataUsedModal,
  dataNotUsedModal,
  bgColor,
  topicsSelected,
} from '@utils/store';
import { language } from '@utils/i18n';
import InterestProfile from 'components/InterestProfile';
import { pushModalTriggered } from 'utils/store';
import useABTesting from 'hooks/useABTesting';

const Profile = () => {
  const { t } = useABTesting({}, 'pages.profile');
  const { name, picture, session, updatePicture, deleteAccount } = useUser();

  const {
    userTopics,
    topics,
    add,
    remove,
    fetch: { mutate: fetchTopics },
  } = useTopics();

  const {
    pushChange: { mutate: handleSubscribeChange },
    loadedOneSignal,
    pushSubscribed,
    pushEnabled,
    notificationSchedule,
  } = useNotifications();
  const [, setUsedModal] = useAtom(dataUsedModal);
  const [, setNotUsedModal] = useAtom(dataNotUsedModal);
  const [selected] = useAtom(topicsSelected);
  const [bgcolor] = useAtom(bgColor);
  const [, setPushModalTriggered] = useAtom(pushModalTriggered);
  const [openDeclaredInterest, setOpenDeclaredInterest] = useState(false);

  useEffect(() => {
    if (session) {
      fetchTopics();
    } else {
      navigate('/sign-in');
    }
  }, [session, fetchTopics]);

  if (!session) {
    return <SplashScreen />;
  }

  const handleDataUsedModal = () => {
    setUsedModal(true);
  };

  const handleDataNotUsedModal = () => {
    setNotUsedModal(true);
  };

  const handleAddInterest = (id) => {
    add.mutate(Number(id));
    setOpenDeclaredInterest(true);
    setTimeout(() => {
      setOpenDeclaredInterest(false);
    }, 2000);
  };

  const handleRemoveInterest = (id) => {
    remove.mutate(Number(id));
  };

  const handlePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      promptLabelHeader: t('picture.labelHeader'),
      promptLabelPhoto: t('picture.labelPhoto'),
      promptLabelPicture: t('picture.labelPicture'),
    });
    const { base64String } = image;
    const imgBuffer = Buffer.from(base64String, 'base64');
    updatePicture.mutate(imgBuffer);
  };

  const handleAccountDelete = async () => {
    const { value } = await Dialog.confirm({
      title: t('settings.deleteAccount.title'),
      message: t('settings.deleteAccount.message'),
      okButtonTitle: t('settings.deleteAccount.button'),
    });
    if (value) {
      deleteAccount.mutate();
    }
  };

  const filterTopics = (type) => {
    return userTopics
      .map((topic) => {
        const id = topic.id || topic;
        const type = topic.type || 'added';
        const topicData = topics.find((topic) => topic?.id === id);
        return {
          id,
          title: topicData?.titles[language],
          type,
        };
      })
      .filter((userTopic) => userTopic.type === type);
  };

  const otherInterests = () => {
    return topics
      .filter((topic) => !selected.includes(topic.id))
      .map(({ id, titles }) => {
        return {
          id,
          title: titles[language],
        };
      });
  };

  const displayNotificationSchedule = () => {
    const translationKey = `settings.${notificationSchedule}`;
    return t(translationKey) || t('settings.morning');
  };

  return (
    <App>
      <Container maxWidth="xs" sx={{ mb: 8, pt: 2 }}>
        <Stack direction="column" spacing={4}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <Fab
                  size="small"
                  color="primary"
                  aria-label="change user picture"
                  onClick={handlePicture}
                >
                  {picture ? <EditIcon /> : <AddAPhotoIcon />}
                </Fab>
              }
            >
              <Avatar
                alt={name}
                src={picture || ' '}
                sx={{
                  width: 120,
                  height: 120,
                  fontSize: 50,
                  bgcolor,
                }}
              />
            </Badge>
            <Typography color="primary" variant="h5">
              {name}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography color="primary" variant="h5">
              {t('aboutYourInterests.title')}
            </Typography>
            <InterestProfile
              title={t('aboutYourInterests.declaredInterests.title')}
              subtitle={t('aboutYourInterests.declaredInterests.subtitle')}
              openWhenAdded={openDeclaredInterest}
              array={filterTopics('added')}
              removeItem={handleRemoveInterest}
            />
            <InterestProfile
              title={t('aboutYourInterests.inferredInterests.title')}
              subtitle={t('aboutYourInterests.inferredInterests.subtitle')}
              array={filterTopics('inferred')}
              removeItem={handleRemoveInterest}
            />
            <InterestProfile
              title={t('aboutYourInterests.otherInterests.title')}
              subtitle={t('aboutYourInterests.otherInterests.subtitle')}
              array={otherInterests()}
              addItem={handleAddInterest}
            />
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography color="primary" variant="h5">
              {t('settings.title')}
            </Typography>
            <Link to="/change-password">
              <Button fullWidth variant="contained">
                {t('settings.changePassword')}
              </Button>
            </Link>
            <Button onClick={handleAccountDelete} fullWidth variant="contained">
              {t('settings.deleteAccount.title')}
            </Button>
            {loadedOneSignal ? (
              <>
                {pushSubscribed ? (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pushEnabled}
                            onChange={(e) =>
                              handleSubscribeChange(e.target.checked)
                            }
                          />
                        }
                        label={t('settings.pushNotifications')}
                      />
                    </FormGroup>
                    {pushEnabled ? (
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body2" color="text.secondary">
                            {t('settings.pushNotificationsPreference')}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {displayNotificationSchedule()}
                          </Typography>
                        </Stack>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => setPushModalTriggered(true)}
                        >
                          {t('settings.changePushNotificationsPreference')}
                        </Button>
                      </>
                    ) : null}
                  </>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setPushModalTriggered(true)}
                  >
                    {t('settings.subcribeNotifications')}
                  </Button>
                )}
              </>
            ) : null}
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography color="primary" variant="h5">
              {t('aboutYourData.title')}
            </Typography>
            <Button fullWidth variant="contained" onClick={handleDataUsedModal}>
              {t('aboutYourData.used')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleDataNotUsedModal}
            >
              {t('aboutYourData.notUsed')}
            </Button>
          </Stack>
        </Stack>
      </Container>
      <DataUsedModal />
      <DataNotUsedModal />
    </App>
  );
};

export default Profile;
